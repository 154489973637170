import React, { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { noop } from "lodash";

import { geospatialConvert, useGetVersionQuery } from "fond/api";
import { AsyncOperationState } from "fond/async/redux";
import { inputLayerGroups, LayerGroupIds, LayerId, LayerIds } from "fond/layers";
import mixpanel from "fond/mixpanel";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { ConfirmModal, useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { POLYGON_UPLOAD_LAYER_ID } from "../polygon";
import { PolygonState, reset, retryDownload, selectDrawNewOption, togglePolygonLayer, uploadPolygonComplete } from "../polygon/redux";
import { closePolygonSelect, dismissUploadError, getCurrentProject, hasLayer, polygonSelectUpload, uploadBegin } from "../redux";
import { UploadSources } from "../upload";
import { getCurrentProjectUploads } from "..";

import DrawPolygon from "./polygon/DrawPolygon";
import Upload from "./upload/Upload";
import UploadProgress from "./upload/UploadProgress";

const PolygonSelectPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const { polygonState, selectedData, selectedArea, selectedLayers, incompleteData, status, isTooBig, downloadState, errorMessage } = useAppSelector(
    (state) => state.project.polygon
  );
  const { activeLayerGroupId } = useAppSelector((state) => state.project);
  const project = useAppSelector((state) => getCurrentProject(state.project));
  const uploads = useAppSelector((state) => getCurrentProjectUploads(state.project));
  const { versionId } = useAppSelector((state) => state.project);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const { data: projectData } = useAppSelector((state) => state.project.projects[state.project.projectId]);
  const { open, goBack, clear } = useStackedNavigationContext();

  const [convertToDualSided, setConvertToDualSided] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [isConfirmingReset, setIsConfirmingReset] = useState(false);

  const upload = uploads[POLYGON_UPLOAD_LAYER_ID];
  const activeLayerName = activeLayerGroupId && inputLayerGroups[activeLayerGroupId]?.name;

  /**
   * The user is only allowed to include UG path importing if they have not
   * already imported AER data using area select.
   */
  const isIncludeUgPathDisabled = useMemo(() => {
    const hasSpanPoleData = hasLayer(projectData, LayerIds.inSpan) || hasLayer(projectData, LayerIds.inPole);
    const spanPoleDataSource: string[] | null = projectData.layers[LayerIds.inSpan]?.sources ?? projectData.layers[LayerIds.inPole]?.sources;
    return (
      activeLayerGroupId === LayerGroupIds.inAddress &&
      hasSpanPoleData &&
      spanPoleDataSource &&
      spanPoleDataSource.includes(UploadSources.polygonSelect)
    );
  }, [activeLayerGroupId, projectData]);

  const handleBack = () => {
    dispatch(closePolygonSelect());

    if (upload && upload.uploadComplete) {
      /**
       * If user's polygon upload is completed but data is being processed,
       * back button should navigate user back to root panel
       */
      clear();
    } else {
      goBack(2);
    }
  };

  const onReset = () => {
    dispatch(reset(version));
    dispatch(selectDrawNewOption());
  };

  const onRetryDownloadClick = () => dispatch(retryDownload());

  const onImportButtonClick = () => {
    dispatch(polygonSelectUpload(convertToDualSided));
    open("import-data");
  };

  if (upload) {
    return (
      <>
        <StackedNavigationHeader onBackButtonClick={handleBack} title="Upload Polygon" />
        <Box px={1} mb={2}>
          <Typography fontWeight="500">{activeLayerName}</Typography>
        </Box>
        <UploadProgress
          upload={upload}
          onDismissUploadError={() => {
            setSelectedFiles(null);
            dispatch(dismissUploadError(POLYGON_UPLOAD_LAYER_ID));
          }}
          inputLayer="polygon"
        />
      </>
    );
  }

  if (polygonState === PolygonState.uploadingPolygon) {
    const onUploadClick = () => {
      dispatch(
        uploadBegin(
          project.ID,
          POLYGON_UPLOAD_LAYER_ID,
          ({ xhrEvents }: any) => geospatialConvert({ selectedFiles: { polygon: selectedFiles }, xhrEvents }),
          (responseText: string) => {
            return (dispatch1: any, getState: any) => {
              const geojson = JSON.parse(responseText);
              dispatch1(uploadPolygonComplete(geojson.Features));
            };
          }
        )
      );
    };
    return (
      <>
        <StackedNavigationHeader onBackButtonClick={handleBack} title="Upload Polygon" />
        <Box px={1}>
          <Typography fontWeight="500" mb={2}>
            {activeLayerName}
          </Typography>
          <Upload
            layer={{
              id: "polygon",
              geometryType: "Polygon",
              name: "Polygon",
            }}
            onSelectFiles={(validFiles) => {
              setSelectedFiles(validFiles);
            }}
            autoUpload={false}
            onBeginUpload={noop}
            onRemove={noop}
            includeLayerName={false}
            convertToDualSidedField={null}
          />
          <Button fullWidth onClick={onUploadClick} variant="contained" disabled={selectedFiles === null || selectedFiles.length === 0}>
            Upload
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      {isConfirmingReset && (
        <ConfirmModal
          open
          header="Resetting input area"
          content="Are you sure you wish to reset your input area? You will lose the area you have drawn."
          confirmText="Reset"
          onConfirm={() => {
            mixpanel.track("Reset area selection");
            onReset();
            setIsConfirmingReset(false);
          }}
          onCancel={() => setIsConfirmingReset(false)}
        />
      )}
      <StackedNavigationHeader onBackButtonClick={handleBack} title="Draw Polygon" />
      <Box px={1}>
        {(polygonState !== PolygonState.complete || downloadState !== AsyncOperationState.success) && (
          <Typography fontWeight="500" mb={2}>
            {activeLayerName}
          </Typography>
        )}
        <DrawPolygon
          polygonState={polygonState}
          downloadState={downloadState}
          selectedLayers={selectedLayers}
          selectedData={selectedData}
          incompleteData={incompleteData}
          version={version}
          togglePolygonLayer={(layerId: LayerId) => dispatch(togglePolygonLayer(layerId))}
          convertToDualSided={convertToDualSided}
          setConvertToDualSided={setConvertToDualSided}
          setIsConfirmingReset={setIsConfirmingReset}
          onImportButtonClick={onImportButtonClick}
          handleBack={handleBack}
          project={project}
          isTooBig={isTooBig}
          selectedArea={selectedArea}
          status={status}
          onReset={onReset}
          onRetryDownloadClick={onRetryDownloadClick}
          isIncludeUgPathDisabled={!!isIncludeUgPathDisabled}
        />
      </Box>
    </>
  );
};

export default PolygonSelectPanel;
